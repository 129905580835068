


.animationScrollText{
    animation: fadeInText 2s linear;
    animation-timeline: view();
    animation-range: entry 20px;
}

@keyframes fadeInText {
    from{opacity: 0;scale: .8;}
    to{opacity: 1;scale: 1;}
}



@keyframes fadeInList {
    0% { opacity: 0; transform: translateY(20px); }
    100% { opacity: 1; transform: translateY(0); }
  }
  
  .list-item {
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInList 0.5s forwards;
  }
  
  .list-item-1 {
    animation-delay: 0.2s;
  }
  
  .list-item-2 {
    animation-delay: 0.4s;
  }
  
  .list-item-3 {
    animation-delay: 0.6s;
  }
  
  .list-item-4 {
    animation-delay: 0.8s;
  }
  
  .list-item-5 {
    animation-delay: 1s;
  }
  


  .animationListScroll{
    animation: listScroll linear;
    animation-timeline: view();
    animation-range: 1% 20%;
    opacity: 1;
    margin-top: 5px;
  }

  
@keyframes listScroll {
    from{
        opacity: 0;
        scale: .8;
        transform: translatex(600px);
    }
    to{opacity: 1;scale: 1;
    
        transform: translatex(0);
    }
}