
/*form-----------*/


.panel-login {
	border-color: #ccc;
	-webkit-box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.2);
	-moz-box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.2);
	box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.2);
}
.panel-login>.panel-heading {
	color: #00415d;
	/*background-color: #fff;*/
	background-color:none; 
	/*border-color: #fff;*/
	text-align:center;
}
.panel-login>.panel-heading a{
	text-decoration: none;
	color: #666;
	font-weight: bold;
	font-size: 15px;
	-webkit-transition: all 0.1s linear;
	-moz-transition: all 0.1s linear;
	transition: all 0.1s linear;
}
.panel-login>.panel-heading a.active{
	color: #eee;
    font-size: 18px;
    background:#464646;
    padding: 7px 29px;
    border-radius: 15px;
}
.panel-login>.panel-heading hr{
	margin-top: 10px;
	margin-bottom: 0px;
	clear: both;
	border: 0;
	height: 1px;
	background-image: -webkit-linear-gradient(left,rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.15),rgba(0, 0, 0, 0));
	background-image: -moz-linear-gradient(left,rgba(0,0,0,0),rgba(0,0,0,0.15),rgba(0,0,0,0));
	background-image: -ms-linear-gradient(left,rgba(0,0,0,0),rgba(0,0,0,0.15),rgba(0,0,0,0));
	background-image: -o-linear-gradient(left,rgba(0,0,0,0),rgba(0,0,0,0.15),rgba(0,0,0,0));
}
.panel-login input[type="text"],.panel-login input[type="email"],.panel-login input[type="password"] {
	height: 45px;
	border: 1px solid #0808087d;
	font-size: 16px;
	-webkit-transition: all 0.1s linear;
	-moz-transition: all 0.1s linear;
	transition: all 0.1s linear;
}
.panel-login input:hover,
.panel-login input:focus {
	outline:none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	border-color: #ccc;
}
.btn-login {
	background-color: #59B2E0;
	outline: none;
	color: #fff;
	font-size: 14px;
	height: auto;
	font-weight: normal;
	padding: 14px 0;
	text-transform: uppercase;
	border-color: #59B2E6;
}
.btn-login:hover,
.btn-login:focus {
	color: #fff;
	background-color: #53A3CD;
	border-color: #53A3CD;
}
.forgot-password {
	text-decoration: underline;
	color: #888;
}
.forgot-password:hover,
.forgot-password:focus {
	text-decoration: underline;
	color: #666;
}

.btn-register {
	background-color: #1CB94E;
	outline: none;
	color: #fff;
	font-size: 14px;
	height: auto;
	font-weight: normal;
	padding: 14px 0;
	text-transform: uppercase;
	border-color: #1CB94A;
}
.btn-register:hover,
.btn-register:focus {
	color: #fff;
	background-color: #1CA347;
	border-color: #1CA347;
}

.panel.panel-login {
	 /*-moz-box-shadow: rgba(200,200,200,1) 0 4px 18px;
    -webkit-box-shadow: rgba(200,200,200,1) 0 4px 18px;*/
    margin: 30px 0px 0px 0px;
    padding: 15px 20px 20px 20px;
       background: rgba(255,255,255,0.8);
        border: 1px solid #0808087d;
} 

/*page -----*/
.header_pg{
	    width: auto;
    background-color: #464646;
    padding:8px 0px 15px 0px;
    color: #ccc;
    margin: 0px 0px 0px 0px;
    height: 43px;
} 
.padding-no{
	padding:0!important; 
	overflow: hidden;
}
.wapper-form {
    margin: 4% auto;
} 
.btn-color{
color: #fff !important;
 background:#464646 !important;	
} 
p#backtosite {
    padding: 0px 15px;
} 
.reg-heading{
	margin: 0 auto;
	text-align: center;
} 
.reg-heading h1 {
    padding: 29px 20px;
    font-size: 2.6em;
} 
.btn-color:hover{
color: #fff !important;
 background: rgb(105, 101, 101) !important;	
} 

.logo-reg{
	margin: 0 auto;
	text-align: center;
	width: 23%;
}
.background-im{
	background-repeat: no-repeat;
	background-size: cover; 
}
@media screen and (max-width:375px) {
.panel-login>.panel-heading a.active {
    color: #eee;
    font-size: 0.7em;
    background: #464646;
    padding: 7px 29px;
    border-radius: 15px;
}
	.btn-group-xs>.btn, .btn-xs {
    padding: 1px 5px;
    font-size: 11px;
    line-height: 1.5;
    border-radius: 3px;
}
}

hr{
	    border: 1px solid #0808087d;
}

/*----------------index page start---------------*/
/*-----------------------------------------------*/
.nav-side-menu {
  overflow: auto;
  font-family: verdana;
  font-size: 12px;
  font-weight: 200;
  background-color: #2e353d;
  position: fixed;
  top: 0px;
  width: 300px;
  height: 100%;
  color: #e1ffff;
}
.nav-side-menu .brand {
  background-color: #23282e;
  line-height: 50px;
  display: block;
  text-align: center;
  font-size: 14px;
}
.nav-side-menu .toggle-btn {
  display: none;
}
.nav-side-menu ul,
.nav-side-menu li {
  list-style: none;
  padding: 0px;
  margin: 0px;
  line-height: 35px;
  cursor: pointer;
  /*    
    .collapsed{
       .arrow:before{
                 font-family: FontAwesome;
                 content: "\f053";
                 display: inline-block;
                 padding-left:10px;
                 padding-right: 10px;
                 vertical-align: middle;
                 float:right;
            }
     }
*/
}
.nav-side-menu ul :not(collapsed) .arrow:before,
.nav-side-menu li :not(collapsed) .arrow:before {
  font-family: FontAwesome;
  content: "\f078";
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: middle;
  float: right;
}
.nav-side-menu ul .active,
.nav-side-menu li .active {
  border-left: 3px solid #d19b3d;
  background-color: #4f5b69;
}
.nav-side-menu ul .sub-menu li.active,
.nav-side-menu li .sub-menu li.active {
  color: #d19b3d;
}
.nav-side-menu ul .sub-menu li.active a,
.nav-side-menu li .sub-menu li.active a {
  color: #d19b3d;
}
.nav-side-menu ul .sub-menu li,
.nav-side-menu li .sub-menu li {
  background-color: #181c20;
  border: none;
  line-height: 28px;
  border-bottom: 1px solid #23282e;
  margin-left: 0px;
}
.nav-side-menu ul .sub-menu li:hover,
.nav-side-menu li .sub-menu li:hover {
  background-color: #020203;
}
.nav-side-menu ul .sub-menu li:before,
.nav-side-menu li .sub-menu li:before {
  font-family: FontAwesome;
  content: "\f105";
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: middle;
}
.nav-side-menu li {
  padding-left: 0px;
  border-left: 3px solid #2e353d;
  border-bottom: 1px solid #23282e;
}
.nav-side-menu li a {
  text-decoration: none;
  color: #e1ffff;
}
.nav-side-menu li a i {
  padding-left: 10px;
  width: 20px;
  padding-right: 20px;
}
.nav-side-menu li:hover {
  border-left: 3px solid #d19b3d;
  background-color: #4f5b69;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}
@media (max-width: 767px) {
  .nav-side-menu {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
  }
  .nav-side-menu .toggle-btn {
    display: block;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 10 !important;
    padding: 3px;
    background-color: #ffffff;
    color: #000;
    width: 40px;
    text-align: center;
  }
  .brand {
    text-align: left !important;
    font-size: 22px;
    padding-left: 20px;
    line-height: 50px !important;
  }
}
@media (min-width: 767px) {
  .nav-side-menu .menu-list .menu-content {
    display: block;
  }
}
body {
  margin: 0px;
  padding: 0px;
}

body {
    position: relative;
    overflow-x: hidden;
}
body,
html { height: 100%;}
.nav .open > a, 
.nav .open > a:hover, 
.nav .open > a:focus {background-color: transparent;}

/*-------------------------------*/
/*           Wrappers            */
/*-------------------------------*/

#wrapper {
    padding-left: 0;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

#wrapper.toggled {
    padding-left: 220px;
}

#sidebar-wrapper {
    z-index: 1000;
    left: 220px;
    width: 0;
    height: 100%;
    margin-left: -220px;
    overflow-y: auto;
    overflow-x: hidden;
    background: #1a1a1a;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

#sidebar-wrapper::-webkit-scrollbar {
  display: none;
}

#wrapper.toggled #sidebar-wrapper {
    width: 220px;
}

#page-content-wrapper {
    width: 100%;
    padding-top: 70px;
}

#wrapper.toggled #page-content-wrapper {
    position: absolute;
    margin-right: -220px;
}

/*-------------------------------*/
/*     Sidebar nav styles        */
/*-------------------------------*/

.sidebar-nav {
    position: absolute;
    top: 0;
    width: 220px;
    margin: 0;
    padding: 0;
    list-style: none;
}

.sidebar-nav li {
    position: relative; 
    line-height: 20px;
    display: inline-block;
    width: 100%;
}

.sidebar-nav li:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    height: 100%;
    width: 3px;
    background-color: #1c1c1c;
    -webkit-transition: width .2s ease-in;
      -moz-transition:  width .2s ease-in;
       -ms-transition:  width .2s ease-in;
            transition: width .2s ease-in;

}
.sidebar-nav li:first-child a {
    color: #fff;
    background-color: #1a1a1a;
}
.sidebar-nav li:nth-child(2):before {
    background-color: #ec1b5a;   
}
.sidebar-nav li:nth-child(3):before {
    background-color: #79aefe;   
}
.sidebar-nav li:nth-child(4):before {
    background-color: #314190;   
}
.sidebar-nav li:nth-child(5):before {
    background-color: #279636;   
}
.sidebar-nav li:nth-child(6):before {
    background-color: #7d5d81;   
}
.sidebar-nav li:nth-child(7):before {
    background-color: #ead24c;   
}
.sidebar-nav li:nth-child(8):before {
    background-color: #2d2366;   
}
.sidebar-nav li:nth-child(9):before {
    background-color: #35acdf;   
}
.sidebar-nav li:hover:before,
.sidebar-nav li.open:hover:before {
    width: 100%;
    -webkit-transition: width .2s ease-in;
      -moz-transition:  width .2s ease-in;
       -ms-transition:  width .2s ease-in;
            transition: width .2s ease-in;

}

.sidebar-nav li a {
    display: block;
    color: #ddd;
    text-decoration: none;
    padding: 10px 15px 10px 30px;    
}

.sidebar-nav li a:hover,
.sidebar-nav li a:active,
.sidebar-nav li a:focus,
.sidebar-nav li.open a:hover,
.sidebar-nav li.open a:active,
.sidebar-nav li.open a:focus{
    color: #fff;
    text-decoration: none;
    background-color: transparent;
}

.sidebar-nav > .sidebar-brand {
    height: 65px;
    font-size: 20px;
    line-height: 44px;
}
.sidebar-nav .dropdown-menu {
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
    border-radius: 0;
    border: none;
    background-color: #222;
    box-shadow: none;
}

/*-------------------------------*/
/*       Hamburger-Cross         */
/*-------------------------------*/

.hamburger {
  position: fixed;
  top: 20px;  
  z-index: 999;
  display: block;
  width: 32px;
  height: 32px;
  margin-left: 15px;
  background: transparent;
  border: none;
}
.hamburger:hover,
.hamburger:focus,
.hamburger:active {
  outline: none;
}
.hamburger.is-closed:before {
  content: '';
  display: block;
  width: 100px;
  font-size: 14px;
  color: #fff;
  line-height: 32px;
  text-align: center;
  opacity: 0;
  -webkit-transform: translate3d(0,0,0);
  -webkit-transition: all .35s ease-in-out;
}
.hamburger.is-closed:hover:before {
  opacity: 1;
  display: block;
  -webkit-transform: translate3d(-100px,0,0);
  -webkit-transition: all .35s ease-in-out;
}

.hamburger.is-closed .hamb-top,
.hamburger.is-closed .hamb-middle,
.hamburger.is-closed .hamb-bottom,
.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-middle,
.hamburger.is-open .hamb-bottom {
  position: absolute;
  left: 0;
  height: 4px;
  width: 100%;
}
.hamburger.is-closed .hamb-top,
.hamburger.is-closed .hamb-middle,
.hamburger.is-closed .hamb-bottom {
  background-color: #1a1a1a;
}
.hamburger.is-closed .hamb-top { 
  top: 5px; 
  -webkit-transition: all .35s ease-in-out;
}
.hamburger.is-closed .hamb-middle {
  top: 50%;
  margin-top: -2px;
}
.hamburger.is-closed .hamb-bottom {
  bottom: 5px;  
  -webkit-transition: all .35s ease-in-out;
}

.hamburger.is-closed:hover .hamb-top {
  top: 0;
  -webkit-transition: all .35s ease-in-out;
}
.hamburger.is-closed:hover .hamb-bottom {
  bottom: 0;
  -webkit-transition: all .35s ease-in-out;
}
.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-middle,
.hamburger.is-open .hamb-bottom {
  background-color: #1a1a1a;
}
.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-bottom {
  top: 50%;
  margin-top: -2px;  
}
.hamburger.is-open .hamb-top { 
  -webkit-transform: rotate(45deg);
  -webkit-transition: -webkit-transform .2s cubic-bezier(.73,1,.28,.08);
}
.hamburger.is-open .hamb-middle { display: none; }
.hamburger.is-open .hamb-bottom {
  -webkit-transform: rotate(-45deg);
  -webkit-transition: -webkit-transform .2s cubic-bezier(.73,1,.28,.08);
}
.hamburger.is-open:before {
  content: '';
  display: block;
  width: 100px;
  font-size: 14px;
  color: #fff;
  line-height: 32px;
  text-align: center;
  opacity: 0;
  -webkit-transform: translate3d(0,0,0);
  -webkit-transition: all .35s ease-in-out;
}
.hamburger.is-open:hover:before {
  opacity: 1;
  display: block;
  -webkit-transform: translate3d(-100px,0,0);
  -webkit-transition: all .35s ease-in-out;
}

/*-------------------------------*/
/*            Overlay            */
/*-------------------------------*/

.overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(250,250,250,.8);
    z-index: 1;
} 
/*index*/
nav.navbar.navbar-inverse.nav-white {
    background: #fff !important;
    border: none;
    box-shadow: 1,1,1,1;
    border-bottom:1px solid #ddd; 
} 
.navbar-inverse .navbar-nav>.active>a, .navbar-inverse .navbar-nav>.active>a:focus, .navbar-inverse .navbar-nav>.active>a:hover {
    color: #9d9d9d;
    background-color: #ffffff;
 border-bottom: 2px solid #1e88c6; 
} 
.navbar-inverse .navbar-nav>li>a:focus, .navbar-inverse .navbar-nav>li>a:hover {
    color:#9d9d9d;
       background-color: #ffffff;
 border-bottom: 2px solid #1e88c6; 
} 
a.navbar-brand.brand-logo img {
    width: 169px;
} 
a.navbar-brand.brand-logo {
    width:80%;
} 
.banner{
    width:100%; 
    position: relative;
    margin: 0;
    padding: 0;
    height:450px; 
}
.header-banner{
  position:absolute;
    z-index:66;
    left:0; 
   
    
}
.box{
    position: absolute;
    z-index: 88;
    left:0px;
    top:40px;
} 
.fb_box{
    background:#496da9;
    color:#fff;
    text-align: center;
    padding:14px; 
}
.fb_box a{
	color:#fff;
	font-size:18px;
	text-decoration:none, 
}
.fb_box1 a{
	color:#fff;
	font-size:18px;
	text-decoration:none, 
}
.fb_box2 a{
	color:#fff;
	font-size:18px;
	text-decoration:none, 
}
.fb_box3 a{
	color:#fff;
	font-size:18px;
	text-decoration:none, 
}
.fb_box4 a{
	color:#fff;
	font-size:18px;
	text-decoration:none, 
}
.fb_box5 a{
	color:#fff;
	font-size:18px;
	text-decoration:none, 
}
.fb_box2{
     background:#64cbee;
    color:#fff;
    text-align: center;
    padding:14px;
} 
.fb_box1{
    background:#e4405f;
    color:#fff;
    text-align: center;
    padding:14px;
} 
.fb_box3{
     background:#1e88c6;
    color:#fff;
    text-align: center;
    padding:14px;
} 
.fb_box4{
     background:#da4735;
    color:#fff;
    text-align: center;
    padding:14px;
} 
.fb_box5{
     background:#34465d;
    color:#fff;
    text-align: center;
    padding:14px;
} 

.banner_butn {
    background: #64cbee;
    width: 181px;
    text-align: center;
    vertical-align: middle;
    position: absolute;
    left:120px;
    z-index: 999;
    border-radius: 12px;
    color: #fff;
    top: 220px;
    padding: 7px;
    text-decoration:none;
    
} 
.banner_butn a{
    text-decoration:none; 
}
.banner_butn:hover{
    border:2px solid #64cbee; 
    color:#64cbee; 
    background:none;
    text-decoration:none;
}
/*-----About us-------*/
/*--------------------*/
.about h2{
    text-align: center;
}
.line-blue{
    background:#93c6e5;
    width:80px;
    height: 2px;
    text-align: center;
    margin: 0 auto;
    
} 

.about_text {
    margin-top:12px; 
    padding: 12px 12px;
    border-right:1px solid #ddd; 
}
.about_text h3{
}
.about_text p {
    font-size: 16px;
} 
.about_text:hover,.about_text.active{
    background:#1e88c6;
    color:#fff;   
} 
.about p {
    font-size: 17px;
    line-height: 2;
    padding: 8px 64px;
}
.about-margin{
    margin: 45px;
    display: block;
}

/*-----About us end-------*/
/*--------------------*


/*--------blue_box--------*/
/*-----------------------*/
.blue_box_text {
    text-align: center;
    background: #1e88c6;
    color: #fff;
    height:302px;
    margin-top: 56px;
    padding: 74px 0;
}
a.btn_white {
    background: #fff;
    padding: 13px 47px;
    margin: 22px 13px;
    border-radius: 12px;
    text-decoration: none;
} 
a.btn_white:hover{
        color: #fff;
    background:none; 
    border:1px solid #fff;
}
.blue_box_text h5 {
    margin-bottom: 32px;
} 

/*--------News--------*/
/*-----------------------*/
.news_hg h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 25px;
}
.news_t {
    margin: 0 auto;
    max-width:315px;
}
a.news_link {
    color: #000;
    font-weight: bold;
    /* margin-bottom: 21px; */
    text-decoration: none;
}

.news_t img{
width:100%;     
 margin: 30px auto !important;    
} 
.news_b{
    width:100%; 
 margin: 0 auto;   
} 

/*--------Work--------*/
/*-----------------------*/
.work h2{
    text-align: center;
}
.line-blue2{
    background:#93c6e5;
    width:180px;
    height: 2px;
    text-align: center;
    margin: 0 auto;   
} 
.work_box p {
    padding: 17px 0px;
    font-size: 17px;
} 
.work_box h4 {
    padding: 13px 0;
} 
a.btn_work{
    border:2px solid #1e88c6;
        border-radius: 25px;
    padding: 10px 61px;
        text-decoration: none;
}
a.btn_work:hover{
background:#1e88c6;
color:#fff;    
}
/*--------Achivment--------*/
/*-----------------------*/
section.achivment {
    margin: 7% auto;
}
.achivment h2{
    text-align: center;
}
.margin-top{
margin-top:4%; 
} 

/*--------footer--------*/
/*-----------------------*/
footer.page-footer.font-small.unique-color-dark.mt-4 {
    background: #000;
    color: #fff;
} 
.footer-copyright.text-center.py-3 {
    padding: 10px 13px;
    border-top: 1px solid #fff;
} 
li.search {
    padding-top: 11px !important;
} 
.text_f a {
    padding: 0px 9px;
    color: #fff;
    margin-top: 7px;
    text-decoration: none;
    display: block;
    float: right;
}
.nav>li {
    position: relative;
    display: block;
    padding: 0 4px;
}
.logo{
    font-size: 70px;
    text-align:center;
} 
.footer-text a {
    text-decoration: none;
    color: #fff;
    padding: 10px 0px;
    display: block;
	font-size:15px; 
}
.footer-text{
	margin:20px 0; 
}
.footer_containt{
	margin:32px 0; 
}
.follow_us {
    margin: 35px;
}
.social_icon {
    margin: 50px 0;
}
.social_icon a {
    text-decoration: none;
    background: #fff;
    font-size: 20px;
    padding: 17px 15px;
    border-radius: 50%;
    color: #000;
}

a.fb {
    padding: 17px 25px;
}
a.in {
    padding: 17px 20px;
}
a.tw {
    padding: 17px 19px;
}
.social_icon a:hover{
	background:#1e88c6;
	color:#fff;
} 
.footer_containt h6 {
    color:#1e88c6;
}
.navbar-inverse .navbar-toggle {
    border-color: #1e88c6;
} 
.navbar-inverse .navbar-toggle .icon-bar {
    background-color: #1e88c6;
}
.navbar-inverse .navbar-toggle {
    border-color:#1e88c6;
}
.navbar-inverse .navbar-toggle:focus, .navbar-inverse .navbar-toggle:hover {
     background-color:none !important; 
}
.navbar-inverse .navbar-toggle:focus, .navbar-inverse .navbar-toggle:hover {
    background-color: #fff !important;
}
.clear-fixe{
    clear: both;
}
/*--------Responsive-----------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------------------------*/
@media screen and (min-width:769px) and (max-width:1024px) {
.banner_butn {
    background: #64cbee;
    width: 181px;
    text-align: center;
    vertical-align: middle;
    position: absolute;
    left: 120px;
    z-index: 999;
    border-radius: 12px;
    color: #fff;
    top: 170px;
    padding: 7px;
    text-decoration: none;
}    
}
@media screen and (min-width:640px) and (max-width:768px) {
 .news_t img {
    width: 77%;
     margin: 10px auto !important;}
     
    a.news_link {
    color: #000;
    font-weight: bold;
    /* margin-bottom: 21px; */
    text-decoration: none;
    font-size: 14px;
}
.clint img {
    text-align: center;
    display: block;
    margin: 0 auto;
  
    }
.box {
    width:53px !important; 
    position: absolute;
    z-index: 88;
    left: 0px;
    top: 0px;
}
    .fb_box1 a {
    color: #fff;
    font-size: 15px;
    text-decoration: none,;
}
    .banner_butn {
    background: #64cbee;
    width: 181px;
    text-align: center;
    vertical-align: middle;
    position: absolute;
    left: 70px;
    z-index: 999;
    border-radius: 12px;
    color: #fff;
    top: 123px;
    padding: 7px;
    text-decoration: none;
}
    .banner {
    width: 100%;
    position: relative;
    margin: 0;
    padding: 0;
    height: 260px;
}
    .nav>li {
    position: relative;
    display: block;
    padding: 4px 2px;
}
.fb_box {
    padding: 6px;
}
    .fb_box1 {
    padding: 6px;
}
        .fb_box2 {
    padding: 6px;
}
        .fb_box3 {
    padding: 6px;
}
        .fb_box4 {
    padding: 6px;
}
} 
@media screen and (min-width:513px) and (max-width:639px) {
  .news_t img {
    width: 77%;
     margin: 10px auto !important;}
     
    a.news_link {
    color: #000;
    font-weight: bold;
    /* margin-bottom: 21px; */
    text-decoration: none;
    font-size: 14px;
}
.clint img {
    text-align: center;
    display: block;
    margin: 0 auto;
  
    }
.box {
    width:43px !important; 
    position: absolute;
    z-index: 88;
    left: 0px;
    top: 0px;
}
    .fb_box1 a {
    color: #fff;
    font-size: 12px;
    text-decoration: none,;
}
    .banner_butn {
    background: #64cbee;
    width: 121px;
    text-align: center;
    vertical-align: middle;
    position: absolute;
    left: 70px;
    z-index: 999;
    border-radius: 12px;
    color: #fff;
    top: 88px;
    padding: 3px;
    text-decoration: none;
}
    .banner {
    width: 100%;
    position: relative;
    margin: 0;
    padding: 0;
    height:181px;
}
    .nav>li {
    position: relative;
    display: block;
    padding: 4px 2px;
}
.fb_box {
    padding: 0px;
}
    .fb_box1 {
    padding: 0px;
}
        .fb_box2 {
    padding: 0px;
}
        .fb_box3 {
    padding: 0px;
}
        .fb_box4 {
    padding:0px;
}   
}
@media screen and (min-width:426px) and (max-width:512px) {
 a.navbar-brand.brand-logo img {
    width: 109px;
}
.banner {
    width: 100%;
    position: relative;
    margin: 0;
    padding: 0;
    height: 169px;
}    
.box {
    display: none;
}
.banner_butn {
    background: #64cbee;
    width: 76px;
    text-align: center;
    vertical-align: middle;
    position: absolute;
    left: 31px;
    z-index: 999;
    border-radius: 7px;
    color: #fff;
    top: 79px;
    padding: 3px;
    text-decoration: none;
}
a.banner_butn {
    font-size: 10px;
}
 .about h2 {
    font-size: 20px;
    text-align: center;
} 
 .about p {
    font-size: 14px;
    line-height: 2;
    padding: 18px 0px;
} 
 .about-margin {
    margin: 0px;
    display: block;
}
 .blue_box_text h2 {
    font-size: 18px;
}
  .blue_box_text h5 {
    font-size: 14px;
} 
 .text_f {
    text-align: center;
    float: none !important;
    margin: 0 auto;
    display: inline-block;
}
    a.fb {
    padding: 5px 11px !important;
}
    a.in {
    padding: 6px 8px !important;
}
    .social_icon a {
    text-decoration: none;
    background: #fff;
    font-size: 12px;
    padding: 6px 7px;
    border-radius: 50%;
    color: #000;
}
    a.btn_work {
    border: 2px solid #1e88c6;
    border-radius: 25px;
    padding: 5px 19px;
    text-decoration: none;
}
.clint img {
    margin: 0 auto;
    /* display: inline-block; */
    text-align: center;
}
  .logo {
    font-size: 50px;
    text-align: center;
} 
    .about_text h3 {
    font-size: 18px;
}
.about_text p {
    font-size: 14px;
}  
    .about_text {
    margin-top: 0px;
    padding: 12px 12px;
    border-right: 1px solid #ddd;
}   
}
@media screen and (min-width:376px) and (max-width:425px) {
 a.navbar-brand.brand-logo img {
    width: 109px;
}
.banner {
    width: 100%;
    position: relative;
    margin: 0;
    padding: 0;
    height:113px;
}    
.box {
    display: none;
}
.banner_butn {
    background: #64cbee;
    width: 76px;
    text-align: center;
    vertical-align: middle;
    position: absolute;
    left: 31px;
    z-index: 999;
    border-radius: 7px;
    color: #fff;
    top: 65px;
    padding: 3px;
    text-decoration: none;
}
a.banner_butn {
    font-size: 10px;
}
 .about h2 {
    font-size: 20px;
    text-align: center;
} 
 .about p {
    font-size: 14px;
    line-height: 2;
    padding: 18px 0px;
} 
 .about-margin {
    margin: 0px;
    display: block;
}
 .blue_box_text h2 {
    font-size: 18px;
}
  .blue_box_text h5 {
    font-size: 14px;
} 
 .text_f {
    text-align: center;
    float: none !important;
    margin: 0 auto;
    display: inline-block;
}
    a.fb {
    padding: 5px 11px !important;
}
    a.in {
    padding: 6px 8px !important;
}
    .social_icon a {
    text-decoration: none;
    background: #fff;
    font-size: 12px;
    padding: 6px 7px;
    border-radius: 50%;
    color: #000;
}
    a.btn_work {
    border: 2px solid #1e88c6;
    border-radius: 25px;
    padding: 5px 19px;
    text-decoration: none;
}
.clint img {
    margin: 0 auto;
    /* display: inline-block; */
    text-align: center;
}
  .logo {
    font-size: 50px;
    text-align: center;
} 
    .about_text h3 {
    font-size: 18px;
}
.about_text p {
    font-size: 14px;
}  
    .about_text {
    margin-top: 0px;
    padding: 12px 12px;
    border-right: 1px solid #ddd;
}   
}
@media screen and (min-width:321px) and (max-width: 375px) {
 a.navbar-brand.brand-logo img {
    width: 109px;
}
.banner {
    width: 100%;
    position: relative;
    margin: 0;
    padding: 0;
    height:113px;
}    
.box {
    display: none;
}
.banner_butn {
    background: #64cbee;
    width: 72px;
    text-align: center;
    vertical-align: middle;
    position: absolute;
    left: 26px;
    z-index: 999;
    border-radius: 7px;
    color: #fff;
    top: 56px;
    padding: 0px;
    text-decoration: none;
}
a.banner_butn {
    font-size: 10px;
}
 .about h2 {
    font-size: 20px;
    text-align: center;
} 
 .about p {
    font-size: 14px;
    line-height: 2;
    padding: 18px 0px;
} 
 .about-margin {
    margin: 0px;
    display: block;
}
 .blue_box_text h2 {
    font-size: 18px;
}
  .blue_box_text h5 {
    font-size: 14px;
} 
 .text_f {
    text-align: center;
    float: none !important;
    margin: 0 auto;
    display: inline-block;
}
    a.fb {
    padding: 5px 11px !important;
}
    a.in {
    padding: 6px 8px !important;
}
    .social_icon a {
    text-decoration: none;
    background: #fff;
    font-size: 12px;
    padding: 6px 7px;
    border-radius: 50%;
    color: #000;
}
    a.btn_work {
    border: 2px solid #1e88c6;
    border-radius: 25px;
    padding: 5px 19px;
    text-decoration: none;
}
.clint img {
    margin: 0 auto;
    /* display: inline-block; */
    text-align: center;
}
  .logo {
    font-size: 50px;
    text-align: center;
} 
    .about_text h3 {
    font-size: 18px;
}
.about_text p {
    font-size: 14px;
}  
    .about_text {
    margin-top: 0px;
    padding: 12px 12px;
    border-right: 1px solid #ddd;
}   
}
@media screen and (max-width:320px) {
    a.navbar-brand.brand-logo img {
    width: 109px;
}
.banner {
    width: 100%;
    position: relative;
    margin: 0;
    padding: 0;
    height: 91px;
}    
.box {
    display: none;
}
.banner_butn {
    background: #64cbee;
    width: 59px;
    text-align: center;
    vertical-align: middle;
    position: absolute;
    left: 22px;
    z-index: 999;
    border-radius: 7px;
    color: #fff;
    top: 49px;
    padding: 0px;
    text-decoration: none;
}
a.banner_butn {
    font-size: 10px;
}
 .about h2 {
    font-size: 20px;
    text-align: center;
} 
 .about p {
    font-size: 14px;
    line-height: 2;
    padding: 18px 0px;
} 
 .about-margin {
    margin: 0px;
    display: block;
}
 .blue_box_text h2 {
    font-size: 18px;
}
  .blue_box_text h5 {
    font-size: 14px;
} 
 .text_f {
    text-align: center;
    float: none !important;
    margin: 0 auto;
    display: inline-block;
}
    a.fb {
    padding: 5px 11px !important;
}
    a.in {
    padding: 6px 8px !important;
}
    .social_icon a {
    text-decoration: none;
    background: #fff;
    font-size: 12px;
    padding: 6px 7px;
    border-radius: 50%;
    color: #000;
}
    a.btn_work {
    border: 2px solid #1e88c6;
    border-radius: 25px;
    padding: 5px 19px;
    text-decoration: none;
}
.clint img {
    margin: 0 auto;
    /* display: inline-block; */
    text-align: center;
}
  .logo {
    font-size: 50px;
    text-align: center;
} 
    .about_text h3 {
    font-size: 18px;
}
.about_text p {
    font-size: 14px;
}  
    .about_text {
    margin-top: 0px;
    padding: 12px 12px;
    border-right: 1px solid #ddd;
}
} 

  .carousel-inner > .item > img,
  .carousel-inner > .item > a > img {
      width: 100%;
      margin: auto;
  }

span.glyphicon.glyphicon-chevron-right {
    display: none;
}
span.glyphicon.glyphicon-chevron-left {
    display: none;
}
.navbar-inverse .navbar-nav>.open>a, .navbar-inverse .navbar-nav>.open>a:focus, .navbar-inverse .navbar-nav>.open>a:hover {
    color: #337ab7;
    background-color: #fff;
}





.dropdown-submenu {
    position: relative;
}

.dropdown-submenu>.dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
    -webkit-border-radius: 0 6px 6px 6px;
    -moz-border-radius: 0 6px 6px;
    border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover>.dropdown-menu {
    display: block;
}

.dropdown-submenu>a:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #ccc;
    margin-top: 5px;
    margin-right: -10px;
}

.dropdown-submenu:hover>a:after {
    border-left-color: #fff;
}

.dropdown-submenu.pull-left {
    float: none;
}

.dropdown-submenu.pull-left>.dropdown-menu {
    left: -100%;
    margin-left: 10px;
    -webkit-border-radius: 6px 0 6px 6px;
    -moz-border-radius: 6px 0 6px 6px;
    border-radius: 6px 0 6px 6px;
}
.dropdown:hover>.dropdown-menu{
	display: block;
}
.dropdown-menu>li>a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #7c7c7c;
    white-space: nowrap;
}
.blue_box_text h2{
margin-bottom: 58px;	
}
.down-logo{
    padding-left:5px;
    color: #ff5050;
}
ul li.scrollTop {
float: right;}