    .animation_scroll_fadeIn{
        scale: 0.6;
        opacity: 0;
        animation: fadeInAnimation linear forwards;
        animation-timeline: view();
        animation-range: entry 20px;
    }
    
    @keyframes fadeInAnimation {
        to{scale:1;opacity: 1;}
    }

        
    .scroller{
        /* overflow: clip; */
        overflow-x: hidden;
        background-color: aqua;
    }

    .scroller-inner{
        padding-block: 1rem;
        display: flex;
        /* flex-wrap: wrap; */
        gap: 1rem;
        /* background-color: aqua  ; */
    }

    .scroller .scroller-inner{
        width: max-content;
        flex-wrap: nowrap;
        animation: bg-shift linear 60s; 
        animation-timeline: scroll();
    }

    .scroller .scroller-inner{
        animation-direction: reverse;
    }


    @keyframes bg-shift {
        from{
            background-color: blueviolet;
        }
        to{
            background-color: blue;
        }
    }

    @keyframes scrolling {
        to{
            transform: translate(calc(-50% - 0.5rem));
        }
    }